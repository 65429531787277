.main1 {
  width: calc(100vw - 30px);
  height: calc(100vh - 30px);
  display: grid;
  grid-template-rows: 50px 1fr 30px;
  grid-template-columns: 1fr 4fr;
  grid-template-areas:
    "header header"
    "sidebar content"
    "footer footer";
}

.main1 .content1 {
  grid-area: content;
  position: relative;
}
.main1 .footer1 {
  grid-area: footer;
  padding: 5;
}
.main1 .header1 {
  grid-area: header;
  padding: 5;
}
.main1 .content1 .images1 {
  overflow-y: scroll;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid silver;
}

.main1 .content1 .images1 .image1 {

}
